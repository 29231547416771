import axios from 'axios';

export default axios.create({
    // URL SETUP
    // baseURL: "http://127.0.0.1:8000/api/",
    baseURL: "https://api.intimclub.fun/api/",
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': '*/*'
    },


});
