<template>
  <div class="form">
    <v-dialog name="dialog-reveal"/>
    <form>

      <h3>{{ $t("security.register_client") }}</h3>
      <br>

      <div class="form-group">
        <label>{{ $t("security.email") }}</label>
        <input type="email" v-model="email" class="form-control form-control-lg"/>
      </div>

      <div class="form-group">
        <label>{{ $t("security.password") }}</label>
        <input type="password" v-model="password" class="form-control form-control-lg"/>
      </div>

      <div class="form-group">
        <label>{{ $t("security.password_again") }}</label>
        <input type="password" class="form-control form-control-lg"/>
      </div>

      <input type="button" @click='register();' :value="$t('security.register_button')" class="btn btn-primar">

      <p class="forgot-password text-right">

        <router-link :to="{name: 'social.loginclientpage'}">{{ $t("security.already_registered") }}</router-link>
      </p>
    </form>

  </div>
</template>

<script>
import {socialvue} from '@/config/pluginInit';
import api from "@/api/api";
import router from "@/router";

export default {
  name: 'SignUpCompanionPage',
  data() {
    return {
      email: null,
      password: null,

      existingRegistrationDialog: {
        title: i18n.t("sign_up_companion.registration_failed"),
        text: i18n.t("sign_up_companion.acc_with_this_email_exists"),
        buttons: [
          {
            title: i18n.t("sign_up_companion.understand"),
            handler: () => {
              this.$modal.hide('dialog')
            }
          }
        ]
      },
    }
  },
  mounted() {
    socialvue.index()

  },
  methods: {
    register() {
      api
          .post("client/register", {
            email: this.email,
            password: this.password
          })
          .then((response) => {
            if (response.data.status) {
              this.$store.dispatch('Setting/authUserAction', response.data)
              this.$store.dispatch('Setting/userLogged', true)
              this.$store.dispatch('Setting/setClientData', response.data.client).then(
                  router.push({path: '/editcustomer'})
              )
            } else {
              this.$modal.show('dialog', this.existingRegistrationDialog);
            }

          })
    }
  }
}
</script>

<style>
input[type="button"] {
  background-color: #ef476f;
  color: white;
  width: 100%;
  height: 50px;
  margin-top: 20px;
}

.vue-dialog {
  background-color: #182039;
  border-radius: 10px;
  border: 5px solid #ef476f;
}

.vue-dialog-content {
  padding: 30px;
}

.vue-dialog-buttons {
  border-top: 5px solid #ef476f;
}

.vue-dialog-button:not(:first-of-type) {
  border-left: 5px solid #ef476f;
}

.vue-dialog-button:hover {
  background: #ef476f;
}
</style>
